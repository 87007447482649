import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink, formatNumber } from "helpers";
import { toast } from "react-toastify";
import EnumPlatform from "model/enum/enumPlatform";

const streamersPopupView: CustomDataGridViewDto = {
  viewName: "Streamer Detail Popup",
  id: "streamer-detail-popup",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "campaign":
        navigate(`/ads/campaigns-popup/${params.row.campaign.id}/overview`);
        break;
      case "streamer":
        // copy clipboard
        if (params.row.platform === EnumPlatform.Twitch) {
          navigator.clipboard.writeText(
            "https://www.twitch.tv/" + params.row.streamer.name
          );
          toast.success("Twitch streamer link copied to clipboard");
        }
        break;
      default:
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    getRowHeight: (params) => {
      if (params.model?.name?.length > 50) return "auto";
    },
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 90,
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 180,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.name}
            image={
              params.value.logo
                ? {
                    rounded: "standard",
                    value: params.value.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "platform",
        headerName: "Platform",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return <CustomComponentCell platform={params.value} />;
        },
      },
      {
        field: "adDisplayScreen",
        headerName: "Display Screen",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(params.value);
                toast.success("Link copied to clipboard");
              }}
            >
              Copy Link
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "referrer",
        headerName: "Referrer",
        width: 180,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.logo
                  ? {
                      rounded: "standard",
                      value: params.value?.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "email",
        headerName: "E-Mail",
        width: 250,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "createdDate",
        headerName: "Created Date",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return new Date(params.value).toLocaleString();
        },
      },
      {
        field: "adViews",
        headerName: "Views",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "adClicks",
        headerName: "Clicks",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "totalRevenue",
        headerName: "Total Revenue",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "streamerRevenue",
        headerName: "Streamer Revenue",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "CTR",
        headerName: "CTR",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value
            ? `${Number(params.value)}%`
            : params.value === 0
            ? "0%"
            : "-",
      },
      {
        field: "autoAcceptPreference",
        headerName: "Auto Accept Preference",
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "autoAcceptMinimum",
        headerName: "Auto Accept Minimum",
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0
            ? `${params.row?.currency?.currencySign || ""} ${formatCurrency(
                params.value
              )}`
            : "-",
      },
      {
        field: "autoAcceptedClients",
        headerName: "Auto Accepted Clients",
        width: 180,
        renderCell: (params: GridRenderCellParams) =>
          params.value?.length
            ? params.value
                .map(
                  (client: { id: number; name: string; logo?: string }) =>
                    client.name + `(${client.id})`
                )
                .join(", ")
            : "-",
      },
    ],
    rowHeight: 40,
    density: "compact",
    hideFooter: true,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default streamersPopupView;
