import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatNumber } from "helpers";
const clientsView: CustomDataGridViewDto = {
  viewName: "Streamers",
  id: "streamers",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "referrer":
        navigate(`streamer-popup/${params.row.referrer.id}/edit-streamer`);
        break;
      default:
        navigate(`streamer-popup/${params.id}/edit-streamer`);
        break;
    }
  },
  gridView: {
    disableVirtualization: true,
    initialState: {
      pinnedColumns: { left: ["id"] },
    },
    columns: [
      {
        field: "id",
        headerName: "ID",
        resizable: true,
        width: 100,
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 180,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.name}
            image={
              params.value.logo
                ? {
                    rounded: "circle",
                    value: params.value.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "platform",
        headerName: "Platform",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return <CustomComponentCell platform={params.value} />;
        },
      },
      {
        field: "country",
        headerName: "Country",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.flag
                  ? {
                      rounded: "standard",
                      value: params.value?.flag,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "broadcastingLanguage",
        headerName: "Language",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.flag
                  ? {
                      rounded: "standard",
                      value: params.value?.flag,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "currency",
        headerName: "Currency",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value.id : "-",
      },
      {
        field: "followerCount",
        headerName: "Follower Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "live",
        headerName: "Live",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "referrer",
        headerName: "Referrer",
        width: 180,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.logo
                  ? {
                      rounded: "standard",
                      value: params.value?.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "teamManager",
        headerName: "Team Manager",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "gender",
        headerName: "Gender",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "email",
        headerName: "E-Mail",
        width: 250,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "createdDate",
        headerName: "Created Date",
        resizable: true,
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "onboarded",
        headerName: "Onboarded",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "signUpCompleted",
        headerName: "Sign Up Completed",
        width: 180,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "modSet",
        headerName: "Mod Set",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "webmGif",
        headerName: "Webm - Gif",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "adFrequency",
        headerName: "Ad Frequency",
        width: 120,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "adsOn",
        headerName: "Ads On",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "automatedAdsOn",
        headerName: "Automated Ads On",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "discordUsername",
        headerName: "Discord Username",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "instagramUsername",
        headerName: "Instagram Username",
        width: 160,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "twitterUsername",
        headerName: "Twitter Username",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "broadcastSoftware",
        headerName: "Broadcasting Software",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value.toString().toUpperCase() : "OTHERS",
      },
      {
        field: "autoAcceptPreference",
        headerName: "Auto Accept Preference",
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "autoAcceptMinimum",
        headerName: "Auto Accept Minimum",
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0
            ? `${params.row?.currency?.currencySign || ""} ${formatCurrency(
                params.value
              )}`
            : "-",
      },
      {
        field: "autoAcceptedClients",
        headerName: "Auto Accepted Clients",
        width: 180,
        renderCell: (params: GridRenderCellParams) =>
          params.value?.length
            ? params.value
                .map(
                  (client: { id: number; name: string; logo?: string }) =>
                    client.name + `(${client.id})`
                )
                .join(", ")
            : "-",
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default clientsView;
