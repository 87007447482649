import GetCampaignPostReviewSettingsResponse from "model/response/campaign/getCampaignPostReviewSettingsResponse";

interface InfluencerCpmInputProps {
  campaignData: GetCampaignPostReviewSettingsResponse;
  setCampaignData: (
    campaignData: GetCampaignPostReviewSettingsResponse
  ) => void;
  currencyId: string;
}
const InfluencerCpmInput = ({
  campaignData,
  setCampaignData,
  currencyId,
}: InfluencerCpmInputProps) => {
  return (
    <>
      <span
        style={{
          textTransform: "uppercase",
        }}
      >
        {currencyId}
      </span>
      <input
        onChange={(e) => {
          const currencyCPM = campaignData.influencerCPMs.find(
            (x) => x.currencyId === currencyId
          );
          if (currencyCPM) {
            currencyCPM.value = parseFloat(e.target.value);
            const influencerCPMs = campaignData.influencerCPMs.filter(
              (x) => x.currencyId !== currencyId
            );
            setCampaignData({
              ...campaignData,
              influencerCPMs: [currencyCPM, ...influencerCPMs],
            });
          } else {
            setCampaignData({
              ...campaignData,
              influencerCPMs: [
                ...campaignData.influencerCPMs,
                {
                  currencyId: currencyId,
                  value: parseFloat(e.target.value),
                },
              ],
            });
          }
        }}
        value={
          campaignData.influencerCPMs.find((x) => x.currencyId === currencyId)
            ?.value || ""
        }
        type="number"
        placeholder={currencyId}
      />
      {campaignData.influencerCPMs.find((x) => x.currencyId === currencyId)
        ?.value ? (
        <button
          onClick={() => {
            const influencerCPMs = campaignData.influencerCPMs.filter(
              (x) => x.currencyId !== currencyId
            );
            setCampaignData({
              ...campaignData,
              influencerCPMs: influencerCPMs,
            });
          }}
        >
          Remove
        </button>
      ) : null}
    </>
  );
};

export default InfluencerCpmInput;
